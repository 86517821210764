<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import SettingService from '@/services/settings'

export default {
  page: {
    title: "Configurações",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Configurações",
      settings: {},
      companies: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let loader = this.$loading.show();

      try {
        const companiesResult = await SettingService.getCompanies();
        this.companies = companiesResult.data;

        const settingsResult = await SettingService.getSettings();
        this.settings = settingsResult.data;

        loader.hide();
      } catch (err) {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao carregar as configurações');
      }
    },
    async save() {
      let loader = this.$loading.show();

      try {
        await SettingService.update({ companyCode: this.settings.companyCode });

        const settingsResult = await SettingService.getSettings();
        this.settings = settingsResult.data;

        this.$toast.open('Configuração atualizada com sucesso');

        loader.hide();
      } catch (err) {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao salvar as configurações');
      }
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="title">Empresa Padrão</label>
                  <select class="custom-select" v-model="settings.companyCode">
                    <option v-for="company in companies" :key="company._id" :value="company.code">{{company.name}}</option>
                  </select>
              </div>
            </div>
          </div>

          <div class="row mt-2 align-items-center">
            <div class="col-sm-6">
              <button type="button" class="btn btn-primary btn-rounded mb-2" @click="save">
                <i class="mdi mdi-check mr-1"></i> Salvar Configuração
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
